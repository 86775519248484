#container {
  width: 960px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: #000000;
}

.clearFix {
  overflow: auto;
}

div h3.single-post-title {
  margin: 2%;
}

body {
  background-color: #595656;
  height: auto;
}

div.header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 2%;
  background-color: #260606;
  color: white;
}

div.post-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #260606;
  color: #A60512;
}

img.single-post-image {
  float: right;
  margin: 2%;
}

div.single-post h1, div.single-post p {
  margin: 2%;
}

.single-post  {
  padding-bottom: 2%;
}

div.figure {
  float: left;
  margin: 2%;
}

div.figure img {
  border-radius: 50%;
}

div.header h1, div.header h2 {
  padding-left: 2%;
  padding-top: 2%;
}

div.header h2 {
  padding-bottom: 2%;
}

div.mainBody {
  background-color: #8c3f3f;
  color: white;
  height: 100%;
  margin-bottom: 2%;
}

.all-post-link {
  float: left;
  width: 30%;
  margin: 2%;
}

.all-post-title {
  margin-top: 2% !important;
}

div.footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #260606;
  color: white;
  padding: 2%; 
}